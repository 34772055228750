import React from "react"
import { useClasses } from '../lib/ReactUtils'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import CTA from '../components/CTA'
import '../styles/Prose'


export default function SLAPage() {
  const root = useClasses()
  return (
    <Layout>
      <SEO title='Service-Level Agreement' description='OneOffice SLA and Support Level'/>
      <main className="main" ref={root}>

        <div className="heading-separator"/>
        <h1 className="title">Our SLA</h1>
        <h2 className="subtitle">Peace of mind included. And that's a promise!</h2>

        <section className='prose'>
            <h2>1. Agreement Overview</h2>
            <p>
                This Agreement represents a Service Level Agreement (“SLA” or “Agreement”) between OneOffice (“OneOffice”, “ZeGenie”, “we”, “our” or “us”) whose website is “https://www.oneoffice.ca” and our customer (“Client”, “Customer”, “you”, “your”) for the provisioning of IT services required to support and sustain application and web-based productivity and collaboration platform provided and hosted by OneOffice on Huawei Cloud (either public or private clouds).
            </p>
            <p>
                This Agreement remains valid until superseded by a revised agreement. This Agreement is effective since 01 Jan 2015. This Agreement was last updated on 07 July 2021.
            </p>
            <p>
                This Agreement outlines the parameters of all IT services covered. This Agreement does not supersede current processes and procedures unless explicitly stated herein.
            </p>


            <h2>2. Goals & Objectives</h2>
            <p>
                The purpose of this Agreement is to ensure that the proper elements and commitments are in place to provide consistent IT service support and delivery to the Customer by us.            
            </p>
            <p>
                The goal of this Agreement is to obtain mutual agreement for IT service provision between OneOffice and you. The specific objectives of this Agreement are to:
            </p>
            <ul>
                <li>Provide clear reference to service ownership, accountability, roles and/or responsibilities. </li>
                <li>Present a clear, concise and measurable description of service provision to the customer. </li>
                <li>Match perceptions of expected service provision with actual service support & delivery.</li>
            </ul>

            <h2>3. Stakeholders</h2>

            <p>
                The following Service Provider and Customer will be used as the basis of the Agreement and represent the primary stakeholders associated with this SLA:            
            </p>
            <p>
                <b>Service Provider</b>: OneOffice (“Provider”)<br/>
                <b>Customer</b>: OneOffice customer (“Customer”)
            </p>

            <h2>4. Periodic Review</h2>
            <p>
                This Agreement is valid from the Effective Date outlined herein and is valid until further notice. This Agreement should be reviewed at a minimum once per fiscal year; however, in lieu of a review during any period specified, the current Agreement will remain in effect.
            </p>

            <p>
                OneOffice is responsible for facilitating regular reviews of this document. Contents of this document may be amended as required only after next review date.
            </p>

            <p>
                Review Period: Yearly (12 months) <br/>
                Next Review Date: July 2022
            </p>

            <h2>5. Service Agreement</h2>

            <h3>5.1 Service Scope</h3>
            <p>
                It covers proper functioning as defined below of web-based and existing or new applications on desktop and mobile for the productivity and collaboration platform provided and hosted by OneOffice in one or more Huawei Cloud data centres (“Platform”, “website”).
            </p>

            <h4>5.1.1 Network Uptime</h4>
            <p>
                Network Uptime is the total time in a calendar month that Platform is available through the Internet to deliver busines services to the Customer, provided that the Client has established connectivity.
            </p>
            <p>
                OneOffice takes responsibility for Platform availability within our network, however, we cannot be held liable for upstream problems, outside of our network. Our guarantee is that our Network will be available to clients free of Network Outages 99.9% of each calendar month.
            </p>

            <h4>5.1.2 Network Outages & Unscheduled Downtime</h4>
            <p>
                Network Outages or Unscheduled downtime is any unplanned or unscheduled interruption in Service availability during which Client is unable to access the services as described in the section titled “Network Uptime” above. A Network Outage is defined as a period in which 100% packet loss to the website is experienced, which is determined to have been caused by a problem in OneOffice’s Network as confirmed by OneOffice.
            </p>
            <p>
                Downtime or outages are measured as the total length of time of the unplanned interruption in service availability in a calendar month.
            </p>

            <h4>5.1.3 Scheduled Downtime</h4>
            <p>
                Scheduled Downtime is any OneOffice scheduled interruption of Services, for the purpose of network upgrades, or replacement of any equipment in order to provide for you better service. Scheduled downtime occurs during notified downtime periods, with as much advance warning as possible via e-mail with a minimum of 24 hours notice. It should be noted that we use very high-availability (VHA) infrastructure meaning it is unlikely all systems are down at the same time.
            </p>
            <p>
                Scheduled downtime typically occurs on Saturday and Sunday from 10pm to 6am EST for an hour. During this time system and server maintenance occurs.
            </p>

            <h4>5.1.4 Web Service Uptime</h4>
            <p>
                Platform Uptime is the total time in a calendar month that OneOffice Platform available through the Internet, provided that the Client has established proper connectivity. Our guarantee is that our Web Services will be available to clients free of outages for 99.99% of each calendar month.
            </p>
            
            <h4>5.1.5 Versioned Backups</h4>
            <p>
                Within OneOffice a versioned control system keeps versions of your documents. You have access to this system from your files panel to see and restore any file. Please refer to the Backup page for more information
            </p>

            <h4>5.1.6 System Backups</h4>
            <p>
                In addition to Versioned Backups OneOffice will backup client data in a commercially reasonable manner, typically at the end of each day. We keep system backups for up to 200 days. OneOffice will provide an electronic copy of the backup to Client upon written request by Client for an additional reasonable fee. Please refer to the Backup page for more information
            </p>

            <h3>5.2 Customer Requirements</h3>
            <p>
                Customer responsibilities and/or requirements in support of this Agreement include:
            </p>
            <ul>
                <li>Payment for all license fees and support costs at the agreed interval.</li>
                <li>Reasonable availability of customer representative(s) when resolving a service related incident or request.</li>
            </ul>

            <h3>5.3 Service Provider Requirements</h3>
            <p>
                Service Provider responsibilities and/or requirements in support of this Agreement include:
            </p>
            <ul>
                <li>Meeting response times associated with service related incidents.</li>
                <li>Appropriate notification to Customer for all scheduled maintenance.</li>
            </ul>

            <h3>5.4 Service Assumptions</h3>
            <p>
                Assumptions related to in-scope services and/or components include:
            </p>
            <ul>
                <li>Changes to services will be communicated and documented to all stakeholders.</li>
                <li>The Platform is hosted on Huawei Cloud and subject to their rules and availability.</li>
            </ul>

            <h2>6. Service Management</h2>
            <p>
                Effective support of in-scope services is a result of maintaining consistent service levels.
            </p>
            <p>
                The following sections provide relevant details on service availability, monitoring of in-scope services and related components.
            </p>

            <h3>6.1. Service Availability</h3>
            <p>
                Coverage parameters specific to the service(s) covered in this Agreement are as follows:
            </p>
            <ul>
                <li>Telephone support for Business plans: 9:00 A.M. to 7:00 P.M. (Monday to Friday) Cairo Time. In case we miss your call, we will call you back within 1 hour.</li>
                <li>Email support: Monitored 8:00 A.M. to 9:00 P.M. (Monday to Friday) Cairo Time.</li>
                <li>Emails received outside of office hours will be collected, however while issues are typically addressed, we cannot formally guarantee action until the following business day.</li>
                <li>Support ticket: Turn-around time of one work day.</li>
                <li>Live Chat: 9:00AM – 7:00PM Cairo Time (Monday to Friday).</li>
            </ul>

            <h3>6.2. Service Requests</h3>
            <p>
                In support of services outlined in this Agreement, the Service Provider will respond to service related incidents and/or requests submitted by the Customer within the following time frames:
            </p>
            <ul>
                <li>0 - 1 hour for issues classified as Show-stopper priority</li>
                <li>Within 24 hours for issues classified as Critical priority</li>
                <li>Within 48 hours for issues classified as Major priority</li>
            </ul>
            <p>
                Remote assistance will be provided in-line with the above timescales dependent on the priority of the support request.
            </p>

            <h2>7. Custom development and Feature requests</h2>
            <p>
                If there is any features not present in OneOffice or some custom changes or additions you want in the platform, the following will be the process:
            </p>
            <ul>
                <li>You will submit your request explaining what addition or change you need in the platform and the reason to requesting the change/addition.</li>
                <li>We will analyze your request and tell you if we will implement it in platform or not. If we do, we will tell you if the said change/addition will be free of cost to you or if it will be chargeable. If it will be chargeable, we will give you an estimate of the number of hours required for the implementation. Our hourly rate for such development work is $90/hr. We will also tell you the approximate date of completion for the said task(s).</li>
                <li>You will, then, either confirm or suggest changes to your requirements or our suggested estimates of hours and delivery dates.</li>
                <li>Based on that, we may increase or decrease the cost of development and the delivery dates.</li>
                <li>The last two steps will be repeated until OneOffice and you come to an agreement post which the development work shall commence.</li>
            </ul>

        </section>

        <CTA variant={1}/>
        
      </main>
    </Layout>

  )
}

